.sign-panel {
	width: calc(550px - var(--spacing-4));
	padding: var(--spacing-3) var(--spacing-4);
	flex-shrink: 0;

	@media (--medium-up) {
		border-right: 1px solid #edf3f8;
	}

	.logo-wrapper {
		width: 50px;
		height: auto;
		margin: 0 0 var(--spacing-4);

		img {
			width: 100%;
		}
	}

	h1 {
		font-family: var(--third-font);
		color: var(--color-3);
		font-weight: 700;
	}

	h2 {
		font-family: var(--secondary-font);
		color: var(--color-3);
		font-weight: 400;
		margin: var(--spacing-1) 0 0;
	}

	.form-wrapper {
		width: 100%;
		margin: var(--spacing-2) 0;
	}
}

.explore-panel {
	width: 100%;
	background-color: var(--bg-color-2);
	padding: var(--spacing-2);
}

.password-strength-indicator {
	width: 100%;
	display: flex;
	margin: 8px 0;
	transition: 500ms linear;

	.indi {
		width: 100%;
		flex-basis: 45%;
		font-family: var(--primary-font);
		font-size: var(--size-5);
		color: var(--color-8);
	}

	.bar {
		width: 100%;
		height: 6px;
		border-radius: 3px;
		position: relative;
		flex-basis: 55%;
		margin: 6px 0;

		span {
			width: 100%;
			height: 100%;
			border-radius: 3px;
			background-color: var(--bg-color-3);
			position: absolute;
		}

		span::before {
			height: 100%;
			display: inline-block;
			border-radius: 3px;
		}
	}
}

.password-strength-indicator.very-weak {
	span {
		color: var(--warning-color-1);
	}
}

.password-strength-indicator.weak {
	span {
		color: #f36739;
	}

	.bar span::before {
		width: 33.333%;
		content: "";
		background-color: #f36739;
	}
}

.password-strength-indicator.medium {
	span {
		color: #ffe034;
	}

	.bar span::before {
		width: 66.667%;
		content: "";
		background-color: #ffe034;
	}
}

.password-strength-indicator.strong {
	span {
		color: #56d771;
	}

	.bar span::before {
		width: 100%;
		content: "";
		background-color: #56d771;
	}
}

.seperator {
	width: 100%;
	height: 1px;
	background-color: var(--color-8);
	position: relative;
	margin: 10px 0;

	span {
		position: absolute;
		width: 20px;
		text-align: center;
		padding: 0 10px;
		left: 0;
		right: 0;
		background-color: var(--bg-color-1);
		z-index: 1;
		display: block;
		margin: 0 auto;
		transform: translateY(-50%);
		font-family: var(--primary-font);
		font-size: var(--size-5);
		color: var(--color-3);
	}
}
