* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	position: relative;
	font-family: var(--primary-font);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

main.MuiContainer-root {
	max-width: none;
	padding: 0;
	margin: 0;
	display: flex;
	height: 100%;
}

.white {
	background-color: var(--bg-color-1);
}

.grey {
	background-color: var(--bg-color-2);
}

.light-grey {
	background-color: var(--bg-color-3);
}

.dark-grey {
	background-color: var(--bg-color-6);
}

.dark-blue {
	background-color: var(--bg-color-4);
}

.darker-blue {
	background-color: var(--bg-color-5);
}

.center {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.black--overlay {
	position: fixed;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	background-color: black;
	z-index: 5;
	-moz-opacity: 0.1;
	opacity: 0.1;
	filter: alpha(opacity=10);
	cursor: pointer;
}

.blurry--overlay {
	top: 0;
	position: fixed;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 5;
	overflow: hidden;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
	transition: 600ms;

	.container {
		max-width: 32.8125rem; /* IE fallback */
		max-width: clamp(20rem, 14.3056rem + 28.4722vw, 32.8125rem);
		margin: min(10vh + 10%, 10rem) auto var(--spacing-2);
		padding: var(--spacing-1);

		.title {
			font-family: var(--primary-font);
			color: var(--color-3);
			font-size: var(--size-10);
			font-weight: 600;
			margin-bottom: var(--spacing-1);
			text-align: center;
		}

		.description {
			font-family: var(--primary-font);
			color: var(--color-8);
			font-size: var(--size-6);
			font-weight: 400;
		}
	}
}

.blurry--overlay.black--windowed {
	background: hsla(0, 0%, 0%, 0.3);

	.container {
		background-color: var(--bg-color-1);
		border-radius: 2px;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	}
}

@keyframes wave {
	0%,
	60%,
	100% {
		transform: initial;
	}

	30% {
		transform: translateY(-15px);
	}
}

.fade-in {
	animation: fadeIn ease 500ms;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
