
@import url('https://fonts.googleapis.com/css?family=Merriweather:300,400,500,700,900');
/*
* Main CSS file for Straact
*
* This file is only for importing other stylesheets. Do NOT insert any css here!
* There may be some specific stylesheets that aren't imported because they're used for unique pages
*/
/*
* Helpers css files
*/
:root {
	/* Colors */
	--color-1: #1b96f5;
	--color-2: #fff;
	--color-3: #435465;
	--color-4: #2c3742;
	--color-5: #636777;
	--color-6: #3b79f0;
	--color-7: #a3a5aa;
	--color-8: #6e748b;
	--warning-color-1: #d64747;
	--success-color-1: #4cc57e;

	/* Background colors */
	--bg-color-1: #fff;
	--bg-color-2: #eff3fc;
	--bg-color-3: #f8f9fa;
	--bg-color-4: #3b79f0;
	--bg-color-5: #1b96f5;
	--bg-color-6: #435465;
	--bg-color-7: #199bff;
	--warning-bg-color-1: #fb9696;
	--success-bg-color-1: #96fbc0;

	/* Spacing */
	--spacing-1: 0.9375rem;
	--spacing-2: 1.5625rem;
	--spacing-3: 3.125rem;
	--spacing-4: 4.6875rem;
	--spacing-5: 6.25rem;
	--spacing-6: 7.8125rem;
	--spacing-7: 9.375rem;
	--spacing-8: 10.9375rem;
	--spacing-9: 12.5rem;

	/* Fonts */
	--primary-font: 'metropolis', sans-serif;
	--secondary-font: 'averta', sans-serif;
	--third-font: 'Noto Serif', serif;

	/* Font sizes
	http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
	Clamp function: Min VW: 320, Max VW: 1040:
	https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport
	https://xgkft.csb.app
	*/
	font-size: 1rem;

	--size-1: 0.625rem;
	--size-2: 0.6875rem;
	--size-3: 0.75rem;
	--size-4: 0.8125rem;
	--size-5: 0.875rem;
	--size-6: 0.9375rem;
	--size-7: 1rem;
	--size-8: 1.125rem;
	--size-9: 1.25rem;
	--size-10: 1.5rem;
	--size-11: 1.75rem;
	--size-12: 2rem;
	--size-13: 2.25rem;
	--size-14: 2.5rem;
	--size-15: 2.75rem;
	--size-16: 3rem;
}
/* Custom media queries */
/*
* Import libraries css files
*/
/*
* Import base css files
*/
* {
	box-sizing: border-box;
}
html,
body {
	margin: 0;
	position: relative;
	font-family: var(--primary-font);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
main.MuiContainer-root {
	max-width: none;
	padding: 0;
	margin: 0;
	display: flex;
	height: 100%;
}
.white {
	background-color: var(--bg-color-1);
}
.grey {
	background-color: var(--bg-color-2);
}
.light-grey {
	background-color: var(--bg-color-3);
}
.dark-grey {
	background-color: var(--bg-color-6);
}
.dark-blue {
	background-color: var(--bg-color-4);
}
.darker-blue {
	background-color: var(--bg-color-5);
}
.center {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
.black--overlay {
	position: fixed;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	background-color: black;
	z-index: 5;
	-moz-opacity: 0.1;
	opacity: 0.1;
	filter: alpha(opacity=10);
	cursor: pointer;
}
.blurry--overlay {
	top: 0;
	position: fixed;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 5;
	overflow: hidden;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
	transition: 600ms;
}
.blurry--overlay .container {
		max-width: 32.8125rem; /* IE fallback */
		max-width: clamp(20rem, 14.3056rem + 28.4722vw, 32.8125rem);
		margin: min(10vh + 10%, 10rem) auto var(--spacing-2);
		padding: var(--spacing-1);
	}
.blurry--overlay .container .title {
			font-family: var(--primary-font);
			color: var(--color-3);
			font-size: var(--size-10);
			font-weight: 600;
			margin-bottom: var(--spacing-1);
			text-align: center;
		}
.blurry--overlay .container .description {
			font-family: var(--primary-font);
			color: var(--color-8);
			font-size: var(--size-6);
			font-weight: 400;
		}
.blurry--overlay.black--windowed {
	background: hsla(0, 0%, 0%, 0.3);
}
.blurry--overlay.black--windowed .container {
		background-color: var(--bg-color-1);
		border-radius: 0.125rem;
		box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.1);
	}
@-webkit-keyframes wave {
	0%,
	60%,
	100% {
		-webkit-transform: initial;
		        transform: initial;
	}

	30% {
		-webkit-transform: translateY(-0.9375rem);
		        transform: translateY(-0.9375rem);
	}
}
@keyframes wave {
	0%,
	60%,
	100% {
		-webkit-transform: initial;
		        transform: initial;
	}

	30% {
		-webkit-transform: translateY(-0.9375rem);
		        transform: translateY(-0.9375rem);
	}
}
.fade-in {
	-webkit-animation: fadeIn ease 500ms;
	        animation: fadeIn ease 500ms;
}
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
/* stylelint-disable */
/*
* Averta
*/
@font-face {
	font-family: averta;
	src: url('../../src/fonts/averta/AvertaDemo-Regular.eot'); /* IE9 Compat Modes */
	src: local('Averta'), url('../../src/fonts/averta/AvertaDemo-Regular.svg');
	src: url('../../src/src/fonts/averta/AvertaDemo-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			 url('../../src/fonts/averta/AvertaDemo-Regular.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../../src/fonts/averta/AvertaDemo-Regular.woff') format('woff'), /* Pretty Modern Browsers */
			 url('../../src/fonts/averta/AvertaDemo-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
			 url('../../src/fonts/averta/AvertaDemo-Regular.svg#AvertaDemo-Regular') format('svg'); /* Legacy iOS */
	font-style: normal;
}
/*
* Metropolis
*/
@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-Light.woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-LightItalic.woff');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-Medium.woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-MediumItalic.woff');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-Bold.woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-BoldItalic.woff');
	font-weight: 700;
	font-style: italic;
}
h1 {
	font-family: var(--third-font);
	font-size: clamp(2rem, 1.5556rem + 2.2222vw, 3rem);
	color: var(--color-8);
	font-weight: 700;
	line-height: 1.1;
	margin: 0 0 0.625rem;
}
h2 {
	font-family: var(--fourth-font);
	font-size: clamp(1.25rem, 1.0278rem + 1.1111vw, 1.75rem);
	color: var(--color-8);
	font-weight: 700;
	line-height: 1.1;
	margin: 0 0 0.625rem;
}
h3 {
	font-family: var(--fourth-font);
	font-size: var(--size-11);
	color: var(--color-8);
	font-weight: 700;
	line-height: 1.1;
	margin: 0 0 0.625rem;
}
h4 {
	font-family: var(--primary-font);
	font-size: var(--size-8);
	color: var(--color-5);
	font-weight: 400;
	line-height: 1.8;
	margin: 0 0 0.625rem;
}
h5 {
	font-family: var(--secondary-font);
	font-size: var(--size-11);
	color: #39b1f0;
	font-weight: 300;
	margin: 0 0 0.625rem;
}
.field-wrapper label.MuiFormControlLabel-root * {
		font-family: var(--primary-font);
	}
.field-wrapper span.MuiFormControlLabel-label {
		color: var(--color-8);
		letter-spacing: normal;
	}
/* Mui form control root */
.field-wrapper .MuiFormControl-root label {
			color: #a1a8c0;
		}
/* Mui input root */
.field-wrapper .MuiInputBase-root {
		font-family: var(--primary-font);
		color: var(--color-3);
		font-size: var(--size-7);
	}
.field-wrapper .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
			border-color: #e3e5ef;
		}
.field-wrapper .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
				border-color: #d7d9e7;
			}
.field-wrapper .MuiInputBase-root .MuiFormLabel-root.Mui-focused {
			color: var(--bg-color-4);
		}
.field-wrapper .MuiInputBase-root .MuiOutlinedInput-root {
			color: var(--color-3);
		}
/* Mui: On input focus border */
.field-wrapper .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: var(--bg-color-4);
		}
/* Mui checkbox root */
.field-wrapper .MuiCheckbox-root {
		color: var(--color-8);
	}
.field-wrapper .MuiCheckbox-root.Mui-checked {
			color: var(--bg-color-4) !important;
		}
.field-wrapper .MuiCheckbox-root:hover {
			background-color: rgba(0, 75, 245, 0.04);
		}
.field-wrapper .error-message {
		padding: 0.75rem;
		background-color: var(--warning-bg-color-1);
		font-family: var(--secondary-font);
		font-weight: 400;
		font-size: var(--size-6);
		color: var(--warning-color-1);
		text-align: center;
		border-radius: 0.25rem;
		margin: 0.625rem 0;
	}
button.MuiButton-root {
	background-color: var(--bg-color-4);
	box-shadow: none;
	margin: var(--spacing-1) 0;
	padding: 0.8125rem 1rem 0.9375rem;
	font-family: var(--primary-font);
	text-transform: uppercase;
	font-weight: 600;
	font-size: var(--size-7);
	letter-spacing: 0.125rem;
}
button.MuiButton-root:hover,
	button.MuiButton-root:focus {
		background-color: var(--bg-color-4);
	}
button.MuiButton-root.disabled {
	background-color: #e1e3e5;
	color: #fff;
	box-shadow: none;
}
button.MuiButton-root.disabled:hover {
		background-color: #e1e3e5;
		color: #fff;
	}
/*
* Import components css files
*/
.center-panel {
	width: 100%;
	overflow-y: scroll;
}
.sign-panel {
	width: calc(34.375rem - var(--spacing-4));
	padding: var(--spacing-3) var(--spacing-4);
	flex-shrink: 0;
}
@media (min-width: 992px) {
.sign-panel {
		border-right: 0.0625rem solid #edf3f8
}
	}
.sign-panel .logo-wrapper {
		width: 3.125rem;
		height: auto;
		margin: 0 0 var(--spacing-4);
	}
.sign-panel .logo-wrapper img {
			width: 100%;
		}
.sign-panel h1 {
		font-family: var(--third-font);
		color: var(--color-3);
		font-weight: 700;
	}
.sign-panel h2 {
		font-family: var(--secondary-font);
		color: var(--color-3);
		font-weight: 400;
		margin: var(--spacing-1) 0 0;
	}
.sign-panel .form-wrapper {
		width: 100%;
		margin: var(--spacing-2) 0;
	}
.explore-panel {
	width: 100%;
	background-color: var(--bg-color-2);
	padding: var(--spacing-2);
}
.password-strength-indicator {
	width: 100%;
	display: flex;
	margin: 0.5rem 0;
	transition: 500ms linear;
}
.password-strength-indicator .indi {
		width: 100%;
		flex-basis: 45%;
		font-family: var(--primary-font);
		font-size: var(--size-5);
		color: var(--color-8);
	}
.password-strength-indicator .bar {
		width: 100%;
		height: 0.375rem;
		border-radius: 0.1875rem;
		position: relative;
		flex-basis: 55%;
		margin: 0.375rem 0;
	}
.password-strength-indicator .bar span {
			width: 100%;
			height: 100%;
			border-radius: 0.1875rem;
			background-color: var(--bg-color-3);
			position: absolute;
		}
.password-strength-indicator .bar span::before {
			height: 100%;
			display: inline-block;
			border-radius: 0.1875rem;
		}
.password-strength-indicator.very-weak span {
		color: var(--warning-color-1);
	}
.password-strength-indicator.weak span {
		color: #f36739;
	}
.password-strength-indicator.weak .bar span::before {
		width: 33.333%;
		content: "";
		background-color: #f36739;
	}
.password-strength-indicator.medium span {
		color: #ffe034;
	}
.password-strength-indicator.medium .bar span::before {
		width: 66.667%;
		content: "";
		background-color: #ffe034;
	}
.password-strength-indicator.strong span {
		color: #56d771;
	}
.password-strength-indicator.strong .bar span::before {
		width: 100%;
		content: "";
		background-color: #56d771;
	}
.seperator {
	width: 100%;
	height: 0.0625rem;
	background-color: var(--color-8);
	position: relative;
	margin: 0.625rem 0;
}
.seperator span {
		position: absolute;
		width: 1.25rem;
		text-align: center;
		padding: 0 0.625rem;
		left: 0;
		right: 0;
		background-color: var(--bg-color-1);
		z-index: 1;
		display: block;
		margin: 0 auto;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		font-family: var(--primary-font);
		font-size: var(--size-5);
		color: var(--color-3);
	}
.left-panel {
	width: 17.1875rem;
	background-color: var(--bg-color-3);
	flex-shrink: 0;
	padding: 0 var(--spacing-2) 0;
	position: relative;
}
.left-panel .logo-wrapper {
		width: 3.125rem;
		height: auto;
		margin: var(--spacing-4) 0 0;
	}
.left-panel .logo-wrapper img {
			width: 100%;
		}
.left-panel nav {
		margin: var(--spacing-6) 0 0; /* IE fallback */
		margin: min(10vh + 10%, 10rem) 0 0;
	}
.left-panel nav ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
.left-panel nav ul a {
			text-decoration: none;
			color: var(--color-8);
		}
.left-panel nav ul li {
			font-family: var(--secondary-font);
			color: var(--color-8);
			margin: var(--spacing-1) 0;
			font-weight: 400;
			font-size: var(--size-9);
			cursor: pointer;
		}
.left-panel nav ul a:hover,
		.left-panel nav ul li:hover {
			color: var(--color-6);
		}
.left-panel .logged-user {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-top: 0.0625rem solid #eee;
		padding: var(--spacing-2);
		display: flex;
	}
.left-panel .logged-user:hover {
			background-color: #f6f6f6;
			cursor: pointer;
		}
.left-panel .logged-user.active .account-arrow .MuiSvgIcon-root {
				-webkit-transform: rotate(180deg);
				        transform: rotate(180deg);
			}
.left-panel .logged-user .meta {
			display: flex;
			flex-wrap: wrap;
			align-content: center;
		}
.left-panel .logged-user .email,
		.left-panel .logged-user .fullname {
			font-family: var(--primary-font);
			color: var(--color-8);
			font-size: var(--size-7);
			flex-basis: 100%;
		}
.left-panel .logged-user .account-type {
			font-family: var(--primary-font);
			color: var(--color-6);
			font-size: var(--size-5);
			flex-basis: 100%;
			margin: 0.25rem 0 0;
			text-transform: capitalize;
		}
.left-panel .logged-user .account-arrow {
			margin-left: auto;
		}
.left-panel .logged-user .account-arrow .MuiSvgIcon-root {
				transition: 300ms;
				fill: var(--color-8);
			}
.left-panel .logged-user__nav {
			width: 100%;
			margin: 0 0 0 var(--spacing-1);
			position: absolute;
			height: auto;
			background-color: var(--bg-color-1);
			box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.04);
			border: 0.0625rem solid #f2f4f6;
			z-index: 1;
			top: 0;
			-webkit-transform: translateY(-100%);
			        transform: translateY(-100%);
			border-radius: 0.25rem;
			cursor: auto;
		}
.left-panel .logged-user__nav .avatar {
				display: flex;
				margin: var(--spacing-1) 0;
			}
.left-panel .logged-user__nav .avatar img {
				width: 2.25rem;
				margin: 0 auto;
			}
.left-panel .logged-user__nav ul {
				margin: 0;
				padding: 0;
				list-style-type: none;
			}
.left-panel .logged-user__nav ul a {
				text-decoration: none;
				color: var(--color-8);
			}
.left-panel .logged-user__nav ul li {
				padding: 1rem 1.5rem;
				font-family: var(--primary-font);
				color: var(--color-8);
				font-size: var(--size-7);
				font-weight: 400;
				border-top: 0.0625rem solid #f2f4f6;
				display: flex;
				flex-wrap: wrap;
				align-content: center;
				cursor: pointer;
			}
.left-panel .logged-user__nav ul li:hover {
				color: var(--color-6);
				background-color: var(--bg-color-3);
			}
.left-panel .logged-user__nav ul li:hover svg {
					fill: var(--color-6);
					-webkit-transform: translateX(-0.25rem);
					        transform: translateX(-0.25rem);
				}
.left-panel .logged-user__nav ul li svg {
				fill: var(--color-8);
				transition: 300ms;
				margin-left: auto;
			}
.left-panel .logged-user__nav ul li span {
				margin: 0 0.375rem 0 0;
				-ms-grid-row-align: center;
				    align-self: center;
			}
.top-bar {
	display: flex;
	align-content: center;
	max-height: 6.25rem;
	padding: var(--spacing-3) var(--spacing-2) 0;
}
.top-bar h1 {
		-ms-grid-row-align: center;
		    align-self: center;
	}
.top-bar .icons-wrapper {
		-ms-grid-row-align: center;
		    align-self: center;
		margin-left: auto;
	}
.top-bar .icons-wrapper svg {
			fill: #b2c3d3;
			transition: 300ms;
			cursor: pointer;
			margin: 0 0.3125rem;
			font-size: 1.75rem;
		}
.top-bar .icons-wrapper svg:hover {
			-webkit-transform: translateY(-0.125rem);
			        transform: translateY(-0.125rem);
		}
.files-view {
	display: flex;
	flex-wrap: wrap;
	padding: var(--spacing-3) var(--spacing-2) 0;
}
.files-view__title {
		width: calc(50% - 2.25rem);
	}
.files-view__date {
		width: calc(50% - 6.25rem);
	}
.files-view .file-item {
		display: flex;
		padding: var(--spacing-1);
		align-items: center;
		color: var(--color-3);
		flex-basis: 100%;
		border-bottom: 0.0625rem solid #f2f4f6;
		text-decoration: none;
	}
.files-view .file-item:hover {
			background-color: var(--bg-color-3);
			cursor: pointer;
			color: var(--color-3);
		}
.files-view .file-item:last-of-type {
			border-bottom: 0;
		}
.files-view .file-item__icon {
			width: 2.25rem;
			flex-shrink: 0;
			color: #b2c3d3;
		}
.files-view .file-item__title {
			font-family: var(--primary-font);
			width: 50%;
		}
.files-view .file-item__date {
			width: 50%;
			font-family: var(--primary-font);
		}
.files-view .file-item__options {
			width: 6.25rem;
			flex-shrink: 0;
			text-align: center;
		}
.preload-files {
	width: 100%;
	box-sizing: border-box;
	padding: var(--spacing-3) var(--spacing-2) 0;
}
.preload-files .preload-block {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: 3.125rem auto;
		grid-template-columns: 3.125rem auto;
		gap: var(--spacing-1);
		height: 3.125rem;
		margin: 0 0 var(--spacing-1);
	}
.preload-files .preload-block .tiny,
		.preload-files .preload-block .wide {
			-webkit-animation: animate-block 1s linear infinite;
			        animation: animate-block 1s linear infinite;
			-webkit-animation-duration: 1s;
			        animation-duration: 1s;
			-webkit-animation-timing-function: ease-out;
			        animation-timing-function: ease-out;
			-webkit-animation-delay: 0s;
			        animation-delay: 0s;
			-webkit-animation-direction: alternate;
			        animation-direction: alternate;
			-webkit-animation-iteration-count: infinite;
			        animation-iteration-count: infinite;
			-webkit-animation-fill-mode: none;
			        animation-fill-mode: none;
			border: none;
			-webkit-animation-play-state: running;
			        animation-play-state: running;
		}
@-webkit-keyframes animate-block {
	0% {
		background: var(--bg-color-3);
	}

	50% {
		background: #f2f6fa;
	}

	100% {
		background: var(--bg-color-3);
	}
}
@keyframes animate-block {
	0% {
		background: var(--bg-color-3);
	}

	50% {
		background: #f2f6fa;
	}

	100% {
		background: var(--bg-color-3);
	}
}
