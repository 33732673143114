.left-panel {
	width: 275px;
	background-color: var(--bg-color-3);
	flex-shrink: 0;
	padding: 0 var(--spacing-2) 0;
	position: relative;

	.logo-wrapper {
		width: 50px;
		height: auto;
		margin: var(--spacing-4) 0 0;

		img {
			width: 100%;
		}
	}

	nav {
		margin: var(--spacing-6) 0 0; /* IE fallback */
		margin: min(10vh + 10%, 10rem) 0 0;

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		ul a {
			text-decoration: none;
			color: var(--color-8);
		}

		ul li {
			font-family: var(--secondary-font);
			color: var(--color-8);
			margin: var(--spacing-1) 0;
			font-weight: 400;
			font-size: var(--size-9);
			cursor: pointer;
		}

		ul a:hover,
		ul li:hover {
			color: var(--color-6);
		}
	}

	.logged-user {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-top: 1px solid #eee;
		padding: var(--spacing-2);
		display: flex;

		&:hover {
			background-color: #f6f6f6;
			cursor: pointer;
		}

		&.active {
			.account-arrow .MuiSvgIcon-root {
				transform: rotate(180deg);
			}
		}

		.meta {
			display: flex;
			flex-wrap: wrap;
			align-content: center;
		}

		.email,
		.fullname {
			font-family: var(--primary-font);
			color: var(--color-8);
			font-size: var(--size-7);
			flex-basis: 100%;
		}

		.account-type {
			font-family: var(--primary-font);
			color: var(--color-6);
			font-size: var(--size-5);
			flex-basis: 100%;
			margin: 4px 0 0;
			text-transform: capitalize;
		}

		.account-arrow {
			margin-left: auto;

			.MuiSvgIcon-root {
				transition: 300ms;
				fill: var(--color-8);
			}
		}

		&__nav {
			width: 100%;
			margin: 0 0 0 var(--spacing-1);
			position: absolute;
			height: auto;
			background-color: var(--bg-color-1);
			box-shadow: 0 5px 15px rgba(0, 0, 0, 0.04);
			border: 1px solid #f2f4f6;
			z-index: 1;
			top: 0;
			transform: translateY(-100%);
			border-radius: 4px;
			cursor: auto;

			.avatar {
				display: flex;
				margin: var(--spacing-1) 0;
			}

			.avatar img {
				width: 36px;
				margin: 0 auto;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style-type: none;
			}

			ul a {
				text-decoration: none;
				color: var(--color-8);
			}

			ul li {
				padding: 16px 24px;
				font-family: var(--primary-font);
				color: var(--color-8);
				font-size: var(--size-7);
				font-weight: 400;
				border-top: 1px solid #f2f4f6;
				display: flex;
				flex-wrap: wrap;
				align-content: center;
				cursor: pointer;
			}

			ul li:hover {
				color: var(--color-6);
				background-color: var(--bg-color-3);

				svg {
					fill: var(--color-6);
					transform: translateX(-4px);
				}
			}

			ul li svg {
				fill: var(--color-8);
				transition: 300ms;
				margin-left: auto;
			}

			ul li span {
				margin: 0 6px 0 0;
				align-self: center;
			}
		}
	}
}
