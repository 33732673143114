h1 {
	font-family: var(--third-font);
	font-size: clamp(2rem, 1.5556rem + 2.2222vw, 3rem);
	color: var(--color-8);
	font-weight: 700;
	line-height: 1.1;
	margin: 0 0 10px;
}

h2 {
	font-family: var(--fourth-font);
	font-size: clamp(1.25rem, 1.0278rem + 1.1111vw, 1.75rem);
	color: var(--color-8);
	font-weight: 700;
	line-height: 1.1;
	margin: 0 0 10px;
}

h3 {
	font-family: var(--fourth-font);
	font-size: var(--size-11);
	color: var(--color-8);
	font-weight: 700;
	line-height: 1.1;
	margin: 0 0 10px;
}

h4 {
	font-family: var(--primary-font);
	font-size: var(--size-8);
	color: var(--color-5);
	font-weight: 400;
	line-height: 1.8;
	margin: 0 0 10px;
}

h5 {
	font-family: var(--secondary-font);
	font-size: var(--size-11);
	color: #39b1f0;
	font-weight: 300;
	margin: 0 0 10px;
}
