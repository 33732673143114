/* stylelint-disable */
@import url('https://fonts.googleapis.com/css?family=Merriweather:300,400,500,700,900');

/*
* Averta
*/
@font-face {
	font-family: averta;
	src: url('../../src/fonts/averta/AvertaDemo-Regular.eot'); /* IE9 Compat Modes */
	src: local('Averta'), url('../../src/fonts/averta/AvertaDemo-Regular.svg');
	src: url('../../src/src/fonts/averta/AvertaDemo-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			 url('../../src/fonts/averta/AvertaDemo-Regular.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../../src/fonts/averta/AvertaDemo-Regular.woff') format('woff'), /* Pretty Modern Browsers */
			 url('../../src/fonts/averta/AvertaDemo-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
			 url('../../src/fonts/averta/AvertaDemo-Regular.svg#AvertaDemo-Regular') format('svg'); /* Legacy iOS */
	font-style: normal;
}

/*
* Metropolis
*/
@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-Light.woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-LightItalic.woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-Medium.woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-MediumItalic.woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-Bold.woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "metropolis";
	src: url('../../src/fonts/metropolis/Metropolis-BoldItalic.woff');
	font-weight: 700;
	font-style: italic;
}
