button.MuiButton-root {
	background-color: var(--bg-color-4);
	box-shadow: none;
	margin: var(--spacing-1) 0;
	padding: 13px 16px 15px;
	font-family: var(--primary-font);
	text-transform: uppercase;
	font-weight: 600;
	font-size: var(--size-7);
	letter-spacing: 2px;

	&:hover,
	&:focus {
		background-color: var(--bg-color-4);
	}
}

button.MuiButton-root.disabled {
	background-color: #e1e3e5;
	color: #fff;
	box-shadow: none;

	&:hover {
		background-color: #e1e3e5;
		color: #fff;
	}
}
