.files-view {
	display: flex;
	flex-wrap: wrap;
	padding: var(--spacing-3) var(--spacing-2) 0;

	&__title {
		width: calc(50% - 36px);
	}

	&__date {
		width: calc(50% - 100px);
	}

	.file-item {
		display: flex;
		padding: var(--spacing-1);
		align-items: center;
		color: var(--color-3);
		flex-basis: 100%;
		border-bottom: 1px solid #f2f4f6;
		text-decoration: none;

		&:hover {
			background-color: var(--bg-color-3);
			cursor: pointer;
			color: var(--color-3);
		}

		&:last-of-type {
			border-bottom: 0;
		}

		&__icon {
			width: 36px;
			flex-shrink: 0;
			color: #b2c3d3;
		}

		&__title {
			font-family: var(--primary-font);
			width: 50%;
		}

		&__date {
			width: 50%;
			font-family: var(--primary-font);
		}

		&__options {
			width: 100px;
			flex-shrink: 0;
			text-align: center;
		}
	}
}

.preload-files {
	width: 100%;
	box-sizing: border-box;
	padding: var(--spacing-3) var(--spacing-2) 0;

	.preload-block {
		display: grid;
		grid-template-columns: 50px auto;
		gap: var(--spacing-1);
		height: 50px;
		margin: 0 0 var(--spacing-1);

		.tiny,
		.wide {
			animation: animate-block 1s linear infinite;
			animation-duration: 1s;
			animation-timing-function: ease-out;
			animation-delay: 0s;
			animation-direction: alternate;
			animation-iteration-count: infinite;
			animation-fill-mode: none;
			border: none;
			animation-play-state: running;
		}
	}
}

@keyframes animate-block {
	0% {
		background: var(--bg-color-3);
	}

	50% {
		background: #f2f6fa;
	}

	100% {
		background: var(--bg-color-3);
	}
}
