.field-wrapper {
	label.MuiFormControlLabel-root * {
		font-family: var(--primary-font);
	}

	span.MuiFormControlLabel-label {
		color: var(--color-8);
		letter-spacing: normal;
	}

	/* Mui form control root */
	.MuiFormControl-root {
		label {
			color: #a1a8c0;
		}
	}

	/* Mui input root */
	.MuiInputBase-root {
		font-family: var(--primary-font);
		color: var(--color-3);
		font-size: var(--size-7);

		.MuiOutlinedInput-notchedOutline {
			border-color: #e3e5ef;
		}

		&:hover {
			.MuiOutlinedInput-notchedOutline {
				border-color: #d7d9e7;
			}
		}

		.MuiFormLabel-root.Mui-focused {
			color: var(--bg-color-4);
		}

		.MuiOutlinedInput-root {
			color: var(--color-3);
		}

		/* Mui: On input focus border */
		&.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: var(--bg-color-4);
		}
	}

	/* Mui checkbox root */
	.MuiCheckbox-root {
		color: var(--color-8);

		&.Mui-checked {
			color: var(--bg-color-4) !important;
		}

		&:hover {
			background-color: rgba(0, 75, 245, 0.04);
		}
	}

	.error-message {
		padding: 12px;
		background-color: var(--warning-bg-color-1);
		font-family: var(--secondary-font);
		font-weight: 400;
		font-size: var(--size-6);
		color: var(--warning-color-1);
		text-align: center;
		border-radius: 4px;
		margin: 10px 0;
	}
}
