.top-bar {
	display: flex;
	align-content: center;
	max-height: 100px;
	padding: var(--spacing-3) var(--spacing-2) 0;

	h1 {
		align-self: center;
	}

	.icons-wrapper {
		align-self: center;
		margin-left: auto;

		svg {
			fill: #b2c3d3;
			transition: 300ms;
			cursor: pointer;
			margin: 0 5px;
			font-size: 28px;
		}

		svg:hover {
			transform: translateY(-2px);
		}
	}
}
